/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useRef, forwardRef } from 'react';
import { StyledComponent } from '@emotion/styled';
import { setRefedElement } from '@s/components/atom/domUtils';

export type BaseAttributes = {
  role?: string;
  tabIndex?: number;
  onKeyUp?(e: React.SyntheticEvent<any>): void;
  style?: any;
} & { [key: string]: any };
type ExtendedProps<T> = T & {
  onUserDecision(e: React.KeyboardEvent<HTMLElement>): void;
};

export const FormRole = <Props extends BaseAttributes, Attrs extends {}>(
  ReactElement:
    | StyledComponent<Props, Attrs, any>
    | StyledComponent<Props, {}, any>
    | React.ComponentClass<Props>
    | React.FunctionComponent<Props>
    | React.ForwardRefRenderFunction<any, Props>
    | React.ClassicComponentClass<Props>,
  role: string
) => {
  return forwardRef(
    (props: ExtendedProps<Attrs extends never ? Props : Props & Attrs>, ref: React.Ref<any>) => {
      const r = useRef<HTMLElement | null>();
      const { onUserDecision, onKeyUp, ...rest } = props;

      return (
        <ReactElement
          {...(rest as any)}
          role={role}
          tabIndex={0}
          ref={(e: HTMLElement | null) => {
            if (e) {
              ref && setRefedElement(ref, e);
              r.current = e;
            }
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => {
            if ((e.key === 'Enter' || e.key === 'Space') && document.activeElement === r.current) {
              onUserDecision(e);
            }
            onKeyUp && onKeyUp(e);
          }}
          onClick={onUserDecision}
        >
          {props.children}
        </ReactElement>
      );
    }
  );
};
