/**
 * @fileOverview
 * @name ProjectListQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from '@s/Query';
import { ProjectEntity } from '@c/domain/entities/Project';
import { fetchService } from '@aim/shared/src/io/fetchService';
import { apiEndpoint } from '@c/config';

export type ProjectListGettableQuery = GettableQuery<undefined, ProjectEntity[]>;

export class ProjectListQuery implements ProjectListGettableQuery {
  public async get(): ReturnType<ProjectListGettableQuery['get']> {
    const ret = await fetchService<{ projects: ProjectEntity[] }>(apiEndpoint(`project/list`), {
      method: 'GET',
      responseType: 'json',
    });
    return ret.projects;
  }
}
