import { staticConfig } from '@c/config';
import { init as SentryInit, setUser as SentrySetUser } from '@sentry/browser';

export const initSentry = (sentryInit: typeof SentryInit = SentryInit) => {
  // FIXME: sentry crashReport から loggerへの移行期間が終わったらコメントアウトを外す
  // if (process.env.NODE_ENV === 'production') {
  sentryInit({
    dsn: staticConfig.sentry.dsn,
    release: import.meta.env.VITE_COMMIT_HASH,
    environment: staticConfig.env,
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // 'Request failed with status code 400',
      'Network Error',
    ],
  });
  // }
};

export const setUserToSentry = (
  user: { email: string; username: string; tenantId?: string; tenantName?: string },
  sentrySetUser: typeof SentrySetUser = SentrySetUser
) => {
  sentrySetUser({
    email: user.email,
    username: user.username,
    tenantId: user.tenantId ?? '',
    tenantName: user.tenantName ?? '',
  });
};
