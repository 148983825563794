/**
 * @fileOverview
 * @name usecase.ts<project>
 * @author Taketoshi Aono
 * @license
 */

import { AsyncActionContext } from '@aim/shared/src/reactHooks';
import { ThunkDeps } from '@c/ThunkDeps';
import { State } from '@c/state';
import {
  fetchProjectListSucceeded,
  projectUpdated,
  projectDeleted,
  getProjectDetailSucceeded,
  projectDetailReset,
  projectFiltered,
  instagramAccountListFetched,
  instagramAccountSelected,
  instagramAccountListResetted,
  instagramAccountSettingFetched,
  lineSettingDeleted,
} from './action';
import { genericError } from '@c/application/GenericError';
import { ProjectDetailEntity, ProjectEntity } from '@c/domain/entities/Project';
import { projectSelected } from '@c/modules/env/action';
import { push } from 'connected-react-router';
import { ProjectDetailParameters } from '@c/domain/specification/ProjectDetailSpecification';
import { FetchFailure } from '@s/io/fetchService';
import { InstagramAccount, InstagramAccountList } from '@c/domain/entities/Instagram';
import { staticConfig } from '@c/config';
import { facebookLoginRequired, loggedInWithFacebook } from '../auth/action';
import {
  LineSettingParameterProperties,
  UpdatedType,
} from '@c/domain/specification/LineSettingSpecification';
import { DEFAULT_LINEPROJECT_SETTING, LineProjectSettingItem } from '@c/domain/entities/Line';
import { TwilioProjectRelationParameter } from '@c/domain/entities/dialogEngine/TwilioProjectRelation';
import {
  addTwilioProjectRelation,
  deleteTwilioProjectRelation,
  updateTwilioProjectRelation,
} from '@c/modules/twilioProjectRelation/usecase';
import { ProjectParameters } from '@c/domain/specification/ProjectSpecification';

type Context = AsyncActionContext<ThunkDeps, State>;

export const fetchProjectList =
  ({ context, dispatch, state }: Context) =>
  async () => {
    try {
      const projectList = await context.projectListQuery.get();
      dispatch(fetchProjectListSucceeded(projectList));
    } catch (error: any) {
      context.reportCrashed({ error, state });
      throw genericError({ message: 'プロジェクト一覧の取得に失敗しました' });
    }
  };

export const createProject =
  ({ context, dispatch, state }: Context) =>
  async (
    e: ProjectEntity,
    integration: ProjectParameters,
    onSucceeded?: (e: ProjectEntity) => Promise<void>
  ) => {
    const [isValid, errorMessage] = integration.validate();
    if (!isValid) {
      throw genericError({ message: errorMessage });
    }

    if (state.project.list.find(v => v.displayName === e.displayName)) {
      throw genericError({ message: `プロジェクト名: ${e.displayName}はすでに存在します` });
    }
    let projectList: ProjectEntity[] | undefined;
    try {
      await context.projectRepository.create(e);
      projectList = await context.projectListQuery.get();
    } catch (error: any) {
      context.reportCrashed({ error, state });
      throw genericError({ message: 'プロジェクトの作成に失敗しました' });
    }

    const project = projectList.find(p => p.displayName === e.displayName)!;
    if (onSucceeded) {
      await onSucceeded(project);
    }
    dispatch(fetchProjectListSucceeded(projectList));
  };

export const updateProject =
  ({ context, dispatch, state, getState, cache, mutate }: Context) =>
  async ({
    project,
    integration,
    line,
    twilioProjectRelations,
  }: {
    project: ProjectDetailEntity;
    integration: ProjectDetailParameters;
    line: LineSettingParameterProperties;
    twilioProjectRelations: TwilioProjectRelationParameter[];
  }) => {
    const [isValid, errorMessage] = integration.validate();
    if (!isValid) {
      throw genericError({ message: errorMessage });
    }
    try {
      await context.projectRepository.update({ ...project, ...integration });
      if (project.type === 'line') {
        const updateInfo = line.generateUpdateInfo(state.project.detail?.lineSetting);
        const updateLineProjectByType = async (
          type: UpdatedType,
          channel: LineProjectSettingItem | undefined,
          isPreview: boolean
        ) => {
          switch (type) {
            case UpdatedType.INIT:
              channel &&
                (await context.lineProjectRepository.create({
                  projectId: project.id,
                  setting: channel,
                  isPreview,
                }));
              break;
            case UpdatedType.EDIT:
              channel &&
                (await context.lineProjectRepository.update({
                  projectId: project.id,
                  setting: channel,
                }));
              break;
            case UpdatedType.NONE:
            default:
          }
        };
        await updateLineProjectByType(updateInfo.channel, line.channel, false);
        await updateLineProjectByType(updateInfo.previewChannel, line.previewChannel, true);
      } else if (project.type === 'voice' || project.type === 'dialogEngineVoice') {
        for (const twilioProjectRelation of state.dialogEngine.twilioProjectRelation) {
          if (!twilioProjectRelations.find(pr => pr.id === twilioProjectRelation.id)) {
            await deleteTwilioProjectRelation({
              context,
              state,
              dispatch,
              getState,
              cache,
              mutate,
            })(project.id, twilioProjectRelation.id!);
          }
        }
        for (const twilioProjectRelation of twilioProjectRelations) {
          if (twilioProjectRelation.id) {
            await updateTwilioProjectRelation({
              context,
              state,
              dispatch,
              getState,
              cache,
              mutate,
            })(project.id, twilioProjectRelation);
          } else {
            delete (twilioProjectRelations as any).tmpId;
            await addTwilioProjectRelation({
              context,
              state,
              dispatch,
              getState,
              cache,
              mutate,
            })(project.id, twilioProjectRelation);
          }
        }
      }
      dispatch(projectUpdated(project));
    } catch (error: any) {
      context.reportCrashed({ error, state });
      throw genericError({ message: `プロジェクト ${project.displayName} の更新に失敗しました` });
    }
  };

export const deleteProject =
  ({ context, dispatch, state }: Context) =>
  async (project: ProjectEntity) => {
    try {
      await context.projectRepository.delete({ id: project.id });
      dispatch(projectDeleted({ id: project.id }));
    } catch (error: any) {
      context.reportCrashed({ error, state });
      throw genericError({ message: `プロジェクト ${project.displayName} の削除に失敗しました` });
    }
  };

export const selectProject =
  (actionContext: Context) =>
  async ({ id, moveTo }: { id: string; moveTo?: string }) => {
    const { context, dispatch, state } = actionContext;
    try {
      context.firestoreService.unsubscribeAllChatMessages({
        projectId: state.env.projectId,
        tenantId: state.env.tenantId,
      });
      const project = state.project.list.find(p => p.id === id);
      dispatch(projectSelected(project));
      if (project) {
        dispatch(projectFiltered({ type: project.type }));
      } else {
        dispatch(projectFiltered({ type: '' }));
      }
      if (moveTo) {
        dispatch(push(moveTo));
      }
    } catch (error: any) {
      context.reportCrashed({ error, state });
      throw genericError({ message: 'プロジェクトの選択に失敗しました' });
    }
  };

export const selectProjectType =
  ({ context, dispatch, state }: Context) =>
  async (type: 'chat' | 'voice') => {
    try {
      if (state.env.projectId) {
        return;
      }
      const project: ProjectEntity = { type, displayName: '', id: '' };
      dispatch(projectSelected(project));
      dispatch(projectFiltered({ type }));
    } catch (error: any) {
      context.reportCrashed({ error, state });
      throw genericError({ message: 'プロジェクトの選択に失敗しました' });
    }
  };

export const resetProjectDetail =
  ({ dispatch }: Context) =>
  async () => {
    dispatch(projectDetailReset());
  };

export const deleteLineConfig =
  ({ context, dispatch }: Context) =>
  async ({
    project,
    lineProjectSettingItem,
    isPreview,
  }: {
    project: ProjectEntity;
    lineProjectSettingItem: LineProjectSettingItem;
    isPreview: boolean;
  }) => {
    try {
      await context.lineProjectRepository.delete({
        projectId: project.id,
        id: lineProjectSettingItem.id,
      });
      dispatch(lineSettingDeleted({ isPreview }));
    } catch (e) {}
  };

export const getProjectDetail =
  ({ context, dispatch, state }: Context) =>
  async ({ project, shouldReset = true }: { project: ProjectEntity; shouldReset?: boolean }) => {
    try {
      if (shouldReset) {
        dispatch(projectDetailReset());
      }
      const projectDetailEntity = await context.projectQuery.get({ projectId: project.id });
      projectDetailEntity.lineSetting = DEFAULT_LINEPROJECT_SETTING;
      if (project.type === 'instagram') {
        if (!(await context.fbService.hasValidAccessToken())) {
          dispatch(facebookLoginRequired());
        } else {
          try {
            projectDetailEntity.instagramSetting = await context.instagramSettingQuery.get({
              projectId: project.id,
            });
            const res = await context.fbService.getIgInfo(
              projectDetailEntity.instagramSetting.page.igid
            );
            if (res?.connected_instagram_account) {
              projectDetailEntity.instagramSetting.page.igAccount = {
                userName: res.connected_instagram_account.username,
                profilePictureUrl: res.connected_instagram_account.profile_picture_url,
                pageId: res.id,
                instagramAccountId: res.connected_instagram_account.id,
                pageAccessToken: res.access_token,
                userAccessToken: await context.fbService
                  .getAccessToken({
                    scope: staticConfig.facebook.projectScopes,
                    shouldStartLoginProcess: false,
                  })
                  .then(id => id!),
              };
              const subscription = await context.fbService.getSubscription({
                pageId: res.id,
                pageAccessToken: res.access_token,
                appId: staticConfig.facebook.projectAppId,
              });
              projectDetailEntity.instagramSetting.page.isSubscribed = subscription.data.length > 0;
              projectDetailEntity.instagramSetting.page.isInstagramMessageControlling =
                await context.fbService.isInstagramMessageControlling({
                  pageId: res.id,
                  pageAccessToken: res.access_token,
                  appId: staticConfig.facebook.projectAppId,
                });
            }
          } catch (e: any) {
            if (!(e instanceof FetchFailure) || e.status !== 404) {
              context.reportCrashed({ error: e, state });
              throw genericError({ message: 'プロジェクト詳細の取得に失敗しました' });
            }
          }
        }
      } else if (project.type === 'line') {
        try {
          const ret = await context.lineSettingQuery.get(project.id);
          if (ret.channel) {
            projectDetailEntity.lineSetting = {
              channel: ret.channel,
              previewChannel: ret.previewChannel,
            };
          } else {
            projectDetailEntity.lineSetting.previewChannel = ret.previewChannel;
          }
        } catch (e: any) {
          if (!(e instanceof FetchFailure) || e.status !== 404) {
            context.reportCrashed({ error: e, state });
            throw genericError({ message: 'プロジェクト詳細の取得に失敗しました' });
          }
        }
      }
      dispatch(getProjectDetailSucceeded(projectDetailEntity));
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({ message: 'プロジェクト詳細の取得に失敗しました' });
    }
  };

export const loginToFacebook =
  ({ context, dispatch, state }: Context) =>
  async ({
    appId,
    shouldForceLogin = false,
  }: { appId?: string; shouldForceLogin?: boolean } = {}) => {
    try {
      await context.fbService.getAccessToken({
        appId: appId || staticConfig.facebook.projectAppId,
        scope: staticConfig.facebook.projectScopes,
        shouldStartLoginProcess: true,
        shouldForceLogin,
      });
      dispatch(loggedInWithFacebook());
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
    }
  };

export const fetchInstagramAccountList =
  ({ context, dispatch, state }: Context) =>
  async ({
    appId,
    next,
    shouldForceLogin = false,
  }: { appId?: string; shouldForceLogin?: boolean; next?: string } = {}) => {
    try {
      if (next) {
        const instaAccounts = await context.fbService.getInstagramAccountsFromPaging({
          appId: appId || staticConfig.facebook.projectAppId,
          url: next,
        });
        dispatch(instagramAccountListFetched(instaAccounts));
        return;
      }
      const res = await context.fbService.getAccessToken({
        appId: appId || staticConfig.facebook.projectAppId,
        scope: staticConfig.facebook.projectScopes,
        shouldForceLogin,
        shouldStartLoginProcess: true,
      });
      if (res) {
        const instaAccounts = await context.fbService.getInstagramAccounts(
          appId || staticConfig.facebook.projectAppId
        );
        dispatch(instagramAccountListFetched(instaAccounts));
      }
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
    }
  };

export const updatePreviewApp =
  ({ context, dispatch, state }: Context) =>
  async ({
    account,
    project,
    clientInfo,
  }: {
    account: InstagramAccount;
    project: ProjectEntity;
    clientInfo: { id: string; secret: string };
  }) => {
    try {
      const pageAccessToken = await context.fbService.getLongTermToken({
        clientId: clientInfo.id,
        clientSecret: clientInfo.secret,
        pageId: account.pageId,
        scope: staticConfig.facebook.projectScopes,
      });

      if (pageAccessToken) {
        const displayName = await context.fbService.getAppName(clientInfo.id);
        await context.facebookAppRepository.create({
          projectId: project.id,
          ...clientInfo,
          verifyToken: 'aim-verify-token',
          displayName,
          pageAccessToken,
        });

        const previewApp = state.project.detail!.instagramSetting!.previewApp;
        if (previewApp) {
          await context.fbService.deleteAppSubscription({
            appId: previewApp.appId,
            accessToken: `${previewApp.appId}|${previewApp.secret}`,
          });
        }
        await context.fbService.deleteAppSubscription({
          appId: clientInfo.id,
          accessToken: `${clientInfo.id}|${clientInfo.secret}`,
        });
        await context.fbService.subscribeApp({
          pageAccessToken,
          appId: clientInfo.id,
          pageId: account.pageId,
        });

        const instagramDetail = await context.instagramSettingQuery.get({
          projectId: project.id,
        });

        dispatch(instagramAccountSettingFetched(instagramDetail));
      }
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      throw genericError({ message: 'Preview用Facebook Appの登録に失敗しました' });
    }
  };

export const refreshInstagramAccount =
  (actionContext: Context) =>
  async ({ detail }: { detail: ReadonlyDeep<ProjectDetailEntity> }) => {
    await fetchInstagramAccountList(actionContext)({ shouldForceLogin: true });
    await selectInstagramAccount(actionContext)({
      account: detail.instagramSetting!.page.igAccount!,
      project: detail,
      isUpdate: true,
    });
    await getProjectDetail(actionContext)({ project: detail, shouldReset: false });
  };

export const selectInstagramAccount =
  ({ context, dispatch, state }: Context) =>
  async ({
    account,
    project,
    isUpdate = false,
  }: {
    account: InstagramAccount;
    project: ProjectEntity;
    isUpdate?: boolean;
  }) => {
    try {
      if (isUpdate) {
        const subscriptions = await context.fbService.getSubscription({
          pageId: account.pageId,
          appId: staticConfig.facebook.projectAppId,
          pageAccessToken: account.pageAccessToken,
        });
        if (subscriptions.data.length && state.project.detail?.instagramSetting) {
          const pageInfo = await context.fbService.getIgInfo(
            state.project.detail.instagramSetting.page.igid
          );
          if (pageInfo) {
            try {
              await context.fbService.unsubscribeApp({
                pageId: pageInfo.id,
                appId: staticConfig.facebook.appId,
                /* eslint-disable-next-line @typescript-eslint/naming-convention */
                pageAccessToken: pageInfo.access_token,
              });
            } catch (e) {}
          }
        }
        await context.facebookPageRepository.update({
          projectId: project.id,
          userAccessToken: account.userAccessToken,
          pageId: state.project.detail!.instagramSetting!.page.id,
          ...(account.pageId !== state.project.detail!.instagramSetting?.page.id
            ? { newPageId: account.pageId }
            : {}),
          ...(account.instagramAccountId !== state.project.detail!.instagramSetting?.page.igid
            ? { iguserId: account.instagramAccountId }
            : {}),
        });
        if (!subscriptions.data.length) {
          dispatch(instagramAccountSelected(account));
          return;
        }
      } else {
        await context.facebookPageRepository.create({
          projectId: project.id,
          iguserId: account.instagramAccountId,
          userAccessToken: account.userAccessToken,
          pageId: account.pageId,
        });
      }
      await context.fbService.subscribeApp({
        pageAccessToken: account.pageAccessToken,
        appId: staticConfig.facebook.projectAppId,
        pageId: account.pageId,
      });
      dispatch(instagramAccountSelected(account));
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      if (e?.status === 409) {
        throw genericError({
          message: `Instagramアカウント${account.userName}はすでに他のプロジェクトで利用しています`,
        });
      }
      throw genericError({ message: 'Instagram Accountの登録に失敗しました' });
    }
  };

export const deleteFacebookApp =
  ({ state, dispatch, context }: Context) =>
  async ({ project }: { project: ProjectEntity }) => {
    try {
      await context.facebookAppRepository.delete({ projectId: project.id });
      const previewApp = state.project.detail!.instagramSetting!.previewApp;
      if (previewApp) {
        await context.fbService.deleteAppSubscription({
          appId: previewApp.appId,
          accessToken: `${previewApp.appId}|${previewApp.secret}`,
        });
      }
      dispatch(instagramAccountSettingFetched());
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      throw genericError({ message: 'Facebook Appの削除に失敗しました' });
    }
  };

export const updateFacebookApp =
  ({ state, context }: Context) =>
  async ({
    account,
    clientInfo,
  }: {
    account: InstagramAccount;
    clientInfo: { id: string; secret: string };
  }) => {
    try {
      const pageAccessToken = await context.fbService.getLongTermToken({
        clientId: clientInfo.id,
        clientSecret: clientInfo.secret,
        pageId: account.pageId,
        scope: staticConfig.facebook.projectScopes,
      });
      if (pageAccessToken) {
        const displayName = await context.fbService.getAppName(clientInfo.id);
        await context.fbService.deleteAppSubscription({
          appId: clientInfo.id,
          accessToken: `${clientInfo.id}|${clientInfo.secret}`,
        });
        await context.facebookAppRepository.update({
          projectId: state.env.projectId,
          ...clientInfo,
          verifyToken: 'aim-verify-token',
          displayName,
          pageAccessToken,
        });
      }
    } catch (e: any) {
      if (!(e instanceof FetchFailure) || e.status !== 401) {
        context.reportCrashed({ error: e, state });
      }
      throw genericError({ message: 'Facebook Appの更新に失敗しました' });
    }
  };

export const resetInstagramAccountList =
  ({ dispatch }: Context) =>
  async () => {
    dispatch(instagramAccountListResetted());
  };

export const setInstagramAccountList =
  ({ dispatch }: Context) =>
  async (accountList: InstagramAccountList) => {
    dispatch(instagramAccountListFetched(accountList));
  };

export const toggleWebhookAvailabillity =
  (actionContext: Context) => async (project: ProjectEntity) => {
    const { context, state } = actionContext;
    try {
      const pageInfo = await context.fbService.getIgInfo(
        state.project.detail!.instagramSetting!.page.igid
      );
      if (!pageInfo) {
        throw genericError({ message: 'Instagramアカウントが存在しないか、権限がありません' });
      }
      if (state.project.detail!.instagramSetting?.page.isSubscribed) {
        await context.fbService.unsubscribeApp({
          pageId: pageInfo.id,
          appId: staticConfig.facebook.projectAppId,
          pageAccessToken: pageInfo.access_token,
        });
      } else {
        await context.fbService.subscribeApp({
          pageId: pageInfo.id,
          appId: staticConfig.facebook.projectAppId,
          pageAccessToken: pageInfo.access_token,
        });
      }
      await getProjectDetail(actionContext)({ project, shouldReset: false });
    } catch (e: any) {
      throw genericError({ message: e.message ? e.message : 'Botの更新に失敗しました' });
    }
  };
