/**
 * @fileOverview
 * @name config.ts
 * @author Taketoshi Aono
 * @license
 */
/// <reference types="vite/client" />
import { AuthEntity } from '@c/domain/entities/AuthEntity';
import { chooseTextColorFromRgb, hslToRgb } from '@s/chooseColor';
import { WidgetCustomizationFile } from '@c/domain/entities/WidgetCustomization';

export const STORAGE_KEY = '__AL__';

export type StorageState = {
  loginState: AuthEntity;
};

export const COLOR_SHCEME_HUE = 192;

export type ColorSchemeOption = {
  saturation?: number;
  lightness?: number;
  opacity?: number;
  hue?: number;
};

export const DEFAULT_COLOR_SCHEME_OPTION = {
  saturation: 100,
  lightness: 50,
  opacity: 1,
  hue: COLOR_SHCEME_HUE,
};

export const chooseTextColorFromScheme = ({
  saturation = 100,
  lightness = 50,
}: ColorSchemeOption = DEFAULT_COLOR_SCHEME_OPTION): 'white' | 'black' => {
  const rgb = hslToRgb(COLOR_SHCEME_HUE, saturation, lightness);
  return chooseTextColorFromRgb(rgb);
};

export const COLOR_SCHEME = ({
  saturation = 100,
  lightness = 50,
  opacity = 1,
  hue = COLOR_SHCEME_HUE,
}: ColorSchemeOption = DEFAULT_COLOR_SCHEME_OPTION) =>
  `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;

export const DEFAULT_ALERT_COLOR = '#FF6600';

export const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export const EXPERIMENTAL_FORCE_LONG_POLLING_TENANT = ['aGlyb2dpbg==', 'bGF3c29u', 'dGhlNzdiYW5r'];

export const LONG_POLLING_PATH = [
  '/preview-check-floating',
  '/preview-check-embedded',
  '/production-check-floating',
  '/production-check-embedded',
];

export const ENV =
  process.env.NODE_ENV === 'production'
    ? (() => {
        const match = /(local|dev|stage)-console/.exec(location.hostname);
        return match ? match[1] : 'prod';
      })()
    : 'dev';
export type ENV = 'local' | 'dev' | 'stage' | 'prod';

export const ENV_PREFIX = ENV === 'prod' ? '' : `${ENV}-`;

export const TENANT_NAME =
  process.env.NODE_ENV === 'production'
    ? location.hostname.split(`.${ENV_PREFIX}console.ai-messenger.app`)[0]
    : 'aim-test';

const NORMALIZE_PATH_REGEX = /\/+/g;
const normalizePath = (url: string) => url.replace(NORMALIZE_PATH_REGEX, '/');

const STATIC_PREFIX =
  process.env.NODE_ENV !== 'production'
    ? location.host
    : `${ENV === 'prod' ? '' : `${ENV}-`}c.ai-messenger-static.app/`;

const STUB_ENDPOINTS: RegExp[] =
  process.env.NODE_ENV !== 'production'
    ? [
        //        /matching-training/,
        //        /resolution-training/,
        //        /\/configuration\//,
        //  /\/scenario\/(?!system_message)/,
        //        /project\/p00000000000000000001\/scenario\/Sbqgj1di8mgu3n5hm1c8g/,
        //        /\/resolution_training\/scenario\//,
      ]
    : [];
export const apiEndpoint = (url: string) =>
  STUB_ENDPOINTS.some(r => r.test(url))
    ? `/${url.replace(/^\//, '')}`
    : process.env.NODE_ENV !== 'production'
    ? `${self.location.protocol}//${normalizePath(`${self.location.host}/dev-console/${url}`)}`
    : normalizePath(`/${url}`);

export const staticFile = (url: string) =>
  `${process.env.NODE_ENV === 'sb' ? 'http' : 'https'}://${normalizePath(
    `${STATIC_PREFIX}/${url}`
  )}`;

export const widgetConfigurationFile = (
  dedicatedServerName: string,
  projectId: string,
  file: WidgetCustomizationFile = WidgetCustomizationFile.pc
) =>
  STUB_ENDPOINTS.some(r => r.test(`/configuration/${projectId}`))
    ? `/configuration/${projectId}`
    : `https://${
        dedicatedServerName ? `${dedicatedServerName}-` : ''
      }${ENV_PREFIX}w-conf.ai-messenger-static.app/configuration/${TENANT_NAME}/${projectId}/${file}.json`;

export const widgetConfigurationFilePreview = (
  dedicatedServerName: string,
  projectId: string,
  file: WidgetCustomizationFile = WidgetCustomizationFile.pc
) =>
  STUB_ENDPOINTS.some(r => r.test(`/configuration/${projectId}`))
    ? `/configuration/${projectId}`
    : `https://${
        dedicatedServerName ? `${dedicatedServerName}-` : ''
      }${ENV_PREFIX}w-conf.ai-messenger-static.app/configuration/${TENANT_NAME}/${projectId}/preview/${file}.json?_=${Date.now()}`;

export const welcomeMessagePreview = (dedicatedServerName: string, projectId: string) =>
  STUB_ENDPOINTS.some(r => r.test(`/configuration/${projectId}`))
    ? `/configuration/${projectId}`
    : `https://${
        dedicatedServerName ? `${dedicatedServerName}-` : ''
      }${ENV_PREFIX}w-conf.ai-messenger-static.app/welcome_message/${TENANT_NAME}/${projectId}/preview/welcome_message.json?_=${Date.now()}`;

export const welcomeMessage = (dedicatedServerName: string, projectId: string) =>
  STUB_ENDPOINTS.some(r => r.test(`/configuration/${projectId}`))
    ? `/configuration/${projectId}`
    : `https://${
        dedicatedServerName ? `${dedicatedServerName}-` : ''
      }${ENV_PREFIX}w-conf.ai-messenger-static.app/welcome_message/${TENANT_NAME}/${projectId}/welcome_message.json`;

export const BASIC_TRANSITION = {
  type: 'tween',
  duration: 0.3,
};

export type ForceSupportableType = 'iOS' | 'Android' | 'PC' | '';

declare const VITE_GET_FLOATING_TAG: (a: {
  projectId: string;
  tenantId: string;
  tenantName: string;
  envPrefix: string;
  forceSupportAs: ForceSupportableType;
  mode: string;
  design: string;
  dedicatedEnvConfig?: string;
}) => string;

export const GET_FLOATING_TAG = VITE_GET_FLOATING_TAG;

declare const VITE_GET_EMBEDDED_TAG: (a: {
  projectId: string;
  tenantId: string;
  tenantName: string;
  envPrefix: string;
  forceSupportAs: ForceSupportableType;
  mode: string;
  design: string;
  dedicatedEnvConfig?: string;
}) => string;

export const GET_EMBEDDED_TAG = VITE_GET_EMBEDDED_TAG;

declare const VITE_GET_EMBEDDED_CONTAINER_TAG: string;

export const EMBEDDED_CONTAINER_TAG = VITE_GET_EMBEDDED_CONTAINER_TAG;

export const ADDITIONALS_TAG = `<script>
if (window.aimWidgetInitialConfigs) {
  window.aimWidgetInitialConfigs.userData = {
    /* EMBED USER DATA HERE */
  }
}
</script>`;

export const PRIVILEDGED_DOMAINS = [
  'cyberagent.co.jp',
  'ai-messenger.jp',
  'ai-shift.jp',
  'ca-adv.co.jp',
  'chatsupportbase.co.jp',
];

export const PRIVILEDGED_DOMAIN_REGEXP = new RegExp(
  PRIVILEDGED_DOMAINS.join('|').replace(/\./g, '\\.')
);

export const INTERNAL_USER_DOMAINS = [
  'ai-shift.com',
  'ai-shift.jp',
  'ca-adv.co.jp',
  'cyberagent.co.jp',
];

export const INTERNAL_USER_DOMAIN_REGEXP = new RegExp(
  INTERNAL_USER_DOMAINS.join('|').replace(/\./g, '\\.')
);

const FACEBOOK_APP_LOGIN_REQUIRED_SCOPES: FacebookAuthScopes['scope'] = ['public_profile'];
export const FACEBOOK_APP_PROJECT_REQUIRED_SCOPES: FacebookAuthScopes['scope'] = [
  'instagram_manage_messages',
  //  'instagram_manage_comments',
  'pages_manage_metadata',
  'pages_show_list',
  'instagram_basic',
];
export const FACEBOOK_APP_PROJECT_REQUIRED_SCOPES_SET = new Set(
  FACEBOOK_APP_PROJECT_REQUIRED_SCOPES
);

export const isExperimentalForceLongPollingTenant = () =>
  !!EXPERIMENTAL_FORCE_LONG_POLLING_TENANT.find(b64Name => atob(b64Name) === TENANT_NAME) ||
  new URL(location.href).searchParams.has('aim_long_polling');

const shouldLongPollingPath = (locPath: string) =>
  !!LONG_POLLING_PATH.find(path => path === locPath);

export const getBoundPushQuery = (locPath: string) => {
  if (isExperimentalForceLongPollingTenant() && shouldLongPollingPath(locPath)) {
    return '?aim_long_polling=1';
  }
  return '';
};

/* eslint-enable @typescript-eslint/naming-convention */

const endpoint =
  process.env.NODE_ENV === 'production'
    ? `https://${TENANT_NAME}.${ENV_PREFIX}console.ai-messenger.app`
    : `http://localhost:8080/dev-console`;

export const staticConfig = (() => {
  return {
    env: import.meta.env.VITE_ENV,
    datasourceEnv: import.meta.env.VITE_DATA_SOURCE_ENV,
    envPrefix: import.meta.env.VITE_ENV_PREFIX,
    sentry: {
      dsn: import.meta.env.VITE_SENTRY_DSN,
    },
    endpoints: {
      message: endpoint,
      base: endpoint,
    },
    chatSupport: {
      ENV_PREFIX: '',
      TENANT_NAME: import.meta.env.VITE_CHAT_SUPPORT_TENANT_NAME,
      TENANT_ID: import.meta.env.VITE_CHAT_SUPPORT_TENANT_ID,
      PROJECT_ID: import.meta.env.VITE_CHAT_SUPPORT_PROJECT_ID,
    },
    firestore: {
      dataSourceEnv: import.meta.env.VITE_DATA_SOURCE_ENV,
      config: {
        apiKey: import.meta.env.VITE_FIRESTORE_API_KEY,
        authDomain: import.meta.env.VITE_FIRESTORE_AUTH_DOMAIN,
        databaseURL: import.meta.env.VITE_FIRESTORE_DATABASE_URL,
        projectId: import.meta.env.VITE_FIRESTORE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIRESTORE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_FIRESTORE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_FIRESTORE_APP_ID,
      },
    },
    facebook: {
      appId: import.meta.env.VITE_FACEBOOK_APP_ID,
      projectAppId: import.meta.env.VITE_FACEBOOK_PROJECT_APP_ID,
      oauthUrl: import.meta.env.VITE_FACEBOOK_OAUTH_URL,
      loginScopes: FACEBOOK_APP_LOGIN_REQUIRED_SCOPES,
      projectScopes: FACEBOOK_APP_PROJECT_REQUIRED_SCOPES,
      previewEndpointPrefix: import.meta.env.VITE_FACEBOOK_PREVIEW_ENDPOINT_PREFIX,
    },
    contentful: {
      spaceID: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
      accessKey: import.meta.env.VITE_CONTENTFUL_ACCESS_KEY,
    },
  };
})();
