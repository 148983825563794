import { UpdatableRepository } from '@s/Repository';
import {
  DialogEngineRouteDraft,
  RouteDraftParameter,
} from '@c/domain/entities/dialogEngine/DialogEngineRouteDraft';
import { fetchService } from '@s/io/fetchService';
import { apiEndpoint } from '@c/config';
import { putFetcher } from '@s/io/swrFetcher';

export type DialogEngineRouteDraftEditableRepository = UpdatableRepository<
  { projectId: string; storyId: string; data: RouteDraftParameter },
  DialogEngineRouteDraft[]
>;

export class DialogEngineRouteDraftRepository implements DialogEngineRouteDraftEditableRepository {
  public async update({
    projectId,
    storyId,
    data,
  }: Parameters<DialogEngineRouteDraftEditableRepository['update']>[0]): ReturnType<
    DialogEngineRouteDraftEditableRepository['update']
  > {
    const ret = await fetchService<DialogEngineRouteDraft[]>(
      apiEndpoint(`/dialog-engine/project/${projectId}/story_draft/${storyId}/route_draft`),
      {
        method: 'PUT',
        responseType: 'json',
        data,
      }
    );
    return ret;
  }
}

export async function updateRoute(url: string, { arg }: { arg: { route: RouteDraftParameter } }) {
  await putFetcher<DialogEngineRouteDraft[]>(`${url}`, arg.route);
}
