/**
 * @fileoverview
 * @author
 */

import { AuthEntity } from '@c/domain/entities/AuthEntity';

export const loginSucceeded = ({ authEntity }: { authEntity: AuthEntity }) => {
  return {
    type: 'LOGIN_SUCCEEDED' as const,
    payload: authEntity,
  };
};

export const deleteAuthEntity = () => {
  return {
    type: 'DELETE_LOGIN_ENTITY' as const,
  };
};

export const facebookAccessTokenStored = (payload: string) => {
  return {
    type: 'FACEBOOK_ACCESS_TOKEN_STORED' as const,
    payload,
  };
};

export const facebookLoginRequired = () => {
  return {
    type: 'FACEBOOK_LOGIN_REQUIRED' as const,
  };
};

export const loggedInWithFacebook = () => {
  return {
    type: 'LOGGED_IN_WITH_FACEBOOK' as const,
  };
};

export const firestoreConnected = () => {
  return {
    type: 'FIRESTORE_CONNECTED' as const,
  };
};

export const firestoreDisconnected = () => {
  return {
    type: 'FIRESTORE_DISCONNECTED' as const,
  };
};
