import { DialogEngineIntent } from '@c/domain/entities/dialogEngine/DialogEngineIntent';
import { DialogEngineScene } from '@c/domain/entities/dialogEngine/DialogEngineScene';
import { MAX_IVR_DIGIT } from '@c/components/environments/DialogEngine/Scenario/constants';

const REGEXP = /^[0-9a-zA-Z]*$/;

export interface DialogEngineSceneSpecification {
  isValidNamespace(namespace: string): [boolean, string];
  isValidIvrDigit(digit: number): [boolean, string];
  isValidIvrFinishKey(finishKey: string): [boolean, string];
  isValidIntents(intents: DialogEngineIntent[]): [boolean, string];
  isValidScenes(scenes: DialogEngineScene[]): [boolean, string];
}

export class DialogEngineSceneValidator implements DialogEngineSceneSpecification {
  private isValidateIntentName = (name: string): [boolean, string] => {
    if (name.length === 0) {
      return [false, 'インテント名は必須です'];
    } else if (!REGEXP.test(name)) {
      return [false, 'インテント名には英数字のみ利用できます'];
    } else if (name.length > 1000) {
      return [false, 'インテント名は1000文字以下です'];
    }
    return [true, ''];
  };

  private isValidateTrainingPhase = (trainingPhases: string[]): [boolean, string] => {
    if (trainingPhases.some(trainingPhase => trainingPhase.length === 0)) {
      return [false, 'トレーニングフレーズは必須です'];
    } else if (trainingPhases.some(trainingPhase => trainingPhase.length > 1000)) {
      return [false, 'トレーニングフレーズは1000文字以下です'];
    }
    return [true, ''];
  };

  public isValidNamespace(namespace: string): [boolean, string] {
    if (!REGEXP.test(namespace)) {
      return [false, 'パラメーター名には英数字のみ利用できます'];
    } else if (namespace.length > 1000) {
      return [false, 'パラメーター名は1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidIvrDigit(digit: number): [boolean, string] {
    if (digit === 0 || digit === MAX_IVR_DIGIT) {
      return [false, '桁数を指定してください'];
    }
    return [true, ''];
  }

  public isValidIvrFinishKey(finishKey: string): [boolean, string] {
    if (!finishKey) {
      return [false, '入力終端キーを指定してください'];
    }
    return [true, ''];
  }

  public isValidIntents(intents: DialogEngineIntent[]): [boolean, string] {
    let errorMessage = '';
    if (
      intents.some(intent => {
        let [isValid, message] = this.isValidateIntentName(intent.name);
        if (!isValid) {
          errorMessage = message;
          return true;
        }
        [isValid, message] = this.isValidateTrainingPhase(intent.trainingPhrases);
        if (!isValid) {
          errorMessage = message;
          return true;
        }
      })
    ) {
      return [false, errorMessage];
    }
    return [true, ''];
  }

  private isValidInitialScene(scene?: DialogEngineScene): [boolean, string] {
    if (!scene) {
      return [false, '開始シーンを設定してください'];
    }

    return [true, ''];
  }

  public isValidScenes(scenes: DialogEngineScene[]): [boolean, string] {
    const [isValid, errorMessage] = this.isValidInitialScene(
      scenes.find(scene => scene.isInitialScene)
    );
    if (!isValid) {
      return [isValid, errorMessage];
    }

    return [true, ''];
  }
}
